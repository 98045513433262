<template>
  <div class="content-direct-sales">
    <Banner pageLocal="about" />
    <section class="content-direct-sales-description">
			<div class="content-direct-sales-description-itens container">
				<div class="content-direct-sales-description-itens-header">
					<h1 class="title">{{ $t("about.title_page")|stripHtml }}</h1>
				</div>			
			</div>
		</section>
		<section class="content-form-default" style="text-align:initial; padding: 40px">
			<div v-html="$t('about.text_page')"></div>
		</section>
  </div>
</template>

<script>
import Banner from "@/components/banners/BannerPages.vue";

export default {
  components: {
    Banner,
  },
};
</script>

<style lang="scss">
.content-direct-sales {
	&-description {
		background: #3D5369;
		padding: 50px 0px;
		text-align: left;
		@media (max-width: 991px){
			text-align: center;
		}
		&-itens {
			&-header {
				margin-bottom: 30px;
				.title {
					position: relative;
					font-size: 40px;
					font-family: 'Font Black';
					color: var(--white);
					margin-bottom: 0;
					padding-bottom: 6px;
					@media (max-width: 991px){
						font-size: 20px;
						font-family: 'Font Medium';
					}
					&::before {
						content: '';
						position: absolute;
						bottom: 0;
						height: 4px;
						width: 128px;
						display: block;
						background: var(--main-color);
						box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
						border-radius: 10px;
						@media (max-width: 991px){
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
			}
			&-body {
				width: 60%;
				@media (max-width: 991px){
					width: 100%;
				}
				@media (min-width: 992px) and (max-width: 1199px){
					width: 50%;
				}
				p {
					font-size: 18px;
					font-family: 'Font Regular';
					color: var(--white);
					margin-bottom: 0;
					@media (max-width: 991px){
						font-size: 16px;
					}
				}
			}
		}
	}
}
</style>